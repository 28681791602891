<template>
  <div class="customer">
    <v-row>
      <v-col>
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col
        class="text-right"
        cols="2"
      >
        <download-csv
          ref="export"
          :data="usersToDownload"
          :separator-excel="true"
          :name="usersToDownloadFileName"
          hidden
        >
        </download-csv>
        <v-btn
          color="primary"
          outlined
          :loading="isDownloadCsvButtonLoading"
          :disabled="isDownloadCsvButtonLoading"
          @click="downloadCSV()"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiDownloadCircleOutline }}
          </v-icon>
          Download
        </v-btn>
      </v-col>
      <v-col
        cols="2"
        class="mr-10"
      >
        <v-btn
          color="primary"
          outlined
          class="mb-5"
          @click="showCreditCustomerAccountDialog = true"
          @account-credited="refreshUsers"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiCash100 }}
          </v-icon>
          Credit Customer
        </v-btn>
      </v-col>
      <v-col
        v-if="can('user-create')"
        class="text-right"
        cols="2"
      >
        <v-btn
          color="primary"
          class="mb-5"
          @click="showAddUserDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiAccountMultiplePlus }}
          </v-icon>
          Add Customer
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        <v-container>
          <v-row>
            <v-col class="col-md-3">
              <refresh-button
                :loading="isUsersRefreshing"
                @click="refreshUsers()"
              />
            </v-col>
            <v-col class="col-md-6">
              <table-filter
                :show-date-filter="false"
                :show-account-balance-status-filter="true"
                @filter="applyFilters"
              />
            </v-col>
            <v-col class="col-md-3 text-right">
              <export-button
                :title="'Customers'"
                :headers="exportTableHeaders"
                :data-endpoint="constructCompleteUsersUrl()"
                :orientation="'landscape'"
              />
            </v-col>
          </v-row><v-row
            justify="center"
            class="mb-0"
          >
            <v-col class="col-md-6 text-center">
              <search-input
                v-model="search"
                :filters="searchFilters"
                :active-filter="activeSearchFilter"
                @filter-change="onSearchFilterChange"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="users"
        :loading="isUsersLoading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:progress>
          <v-progress-linear
            indeterminate
            class="mt-2"
            rounded
          ></v-progress-linear>
          <p class="text-center mt-1">
            Loading...Please wait
          </p>
        </template>
        <template v-slot:body="{ items }">
          <tbody v-if="users.length > 0">
            <tr
              v-for="user in items"
              :key="user.id"
              style="cursor: pointer"
              @click="onCustomerClick(user.id)"
            >
              <td>
                <span class="primary--text font-weight-medium">{{ user.account_number }}</span>
              </td>
              <td>{{ user.name }}</td>
              <td>{{ user.phone }}</td>
              <td>
                <span class="primary--text font-weight-medium">{{ user.email }}</span>
              </td>
              <td>
                <span class="primary--text font-weight-medium">{{ user.meter.location }}</span>
              </td>
              <td>
                <span class="primary--text font-weight-medium">{{ user.meter.number }}</span>
              </td>
              <td>{{ user.account_balance | formatTotalUserDebt(user.unaccounted_debt) }}</td>
              <td>{{ user.account_balance | formatTotalUserCredit }}</td>
              <td @click.stop>
                <v-tooltip
                  v-if="can('user-edit')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ma-2
                      v-bind="attrs"
                      text
                      icon
                      @click="editUser(user)"
                      v-on="on"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiPencil }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip
                  v-if="can('user-delete')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ma-2
                      v-bind="attrs"
                      text
                      icon
                      @click="showDeleteUserDialog(user.id, user.name)"
                      v-on="on"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiDelete }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-show="!isUsersLoading">
              <td
                :colspan="headers.length"
                style="text-align: center"
              >
                No customers found
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-divider />
      <data-table-pagination
        :page="pagination.page"
        :page-count="pagination.pageCount"
        @page-change="onPageChange"
        @items-per-page-change="onItemsPerPageChange"
      />
    </v-card>
    <credit-customer-account
      :show-dialog="showCreditCustomerAccountDialog"
      @close="showCreditCustomerAccountDialog = false"
    />
    <add-user-dialog
      :show-dialog="showAddUserDialog"
      :user="user"
      @close="
        showAddUserDialog = false
        user = {}
      "
      @user-added="getUsers()"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :title="'Remove customer?'"
      :agree-text="'Remove'"
      :message="deleteUserMessage"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteUser()"
    />
  </div>
</template>
<script>
import axios from 'axios'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import {
  mdiMagnify, mdiPencil, mdiDelete, mdiAccountMultiplePlus, mdiDownloadCircleOutline, mdiCash100,
} from '@mdi/js'
import hasPermission from '@/mixins/hasPermission'
import AddUserDialog from '@/components/dialogs/AddCustomerDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import SearchInput from '@/components/partials/SearchInput.vue'
import TableFilter from '@/components/partials/table-filter/index.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import ExportButton from '@/components/partials/ExportButton.vue'
import CreditCustomerAccount from '@/components/dialogs/CreditCustomerAccount.vue'
import DataTablePagination from '@/components/partials/DataTablePagination.vue'

export default {
  components: {
    AddUserDialog,
    ConfirmDialog,
    breadcrumb: BreadCrumb,
    TableFilter,
    SearchInput,
    RefreshButton,
    ExportButton,
    CreditCustomerAccount,
    DataTablePagination,
  },
  mixins: [hasPermission],
  data() {
    return {
      sortBy: ['account_number'],
      sortDesc: [false],
      search: '',
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
      headers: [
        { text: 'Account Number', value: 'account_number', sortable: true },
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Phone',
          value: 'phone',
          sortable: false,
        },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Location', value: 'location', sortable: false },
        { text: 'Meter Number', value: 'meter_number', sortable: false },
        { text: 'Debt', value: 'debt', sortable: false },
        { text: 'Credit', value: 'credit', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      activeSearchFilter: 'account_number',
      searchFilters: [
        {
          text: 'Account number',
          value: 'account_number',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Phone number',
          value: 'phone',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Location',
          value: 'location',
        },
        {
          text: 'Meter number',
          value: 'meters.number',
        },
      ],
      icons: {
        mdiMagnify,
        mdiPencil,
        mdiDelete,
        mdiCash100,
        mdiAccountMultiplePlus,
        mdiDownloadCircleOutline,
      },
      user: {},
      users: [],
      usersToDownload: [],
      usersToDownloadFileName: '',
      userUrl: 'users',
      isUsersLoading: true,
      showAddUserDialog: false,
      showCreditCustomerAccountDialog: false,
      showConfirmDeleteDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      isUsersRefreshing: false,
      isDownloadCsvButtonLoading: false,
      toDelete: null,
      toDeleteCustomerName: null,
      customerId: this.$route.params.id,
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Customers',
          disabled: true,
          to: { name: 'customers' },
        },
      ],
      filters: {
        accountBalanceStatus: [],
      },
      exportTableHeaders: [],
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.selectedStation}|${this.userUrl}|${this.search}|${this.sortBy}|${this.sortDesc}`
    },
    deleteUserMessage() {
      return `Are you sure you want to remove ${this.toDeleteCustomerName} from customers?`
    },
  },
  watch: {
    refreshTable() {
      this.getUsers()
    },
    activeSearchFilter() {
      if (this.search !== '') {
        this.getUsers()
      }
    },
  },
  mounted() {
    if (!(this.can('user-edit') && this.can('user-delete'))) {
      this.removeActionsHeader()
    }
    this.getUsers(this.customerId)
    if (this.customerId) {
      this.breadcrumbs.pop()
      this.breadcrumbs.push(
        {
          text: 'Customers',
          disabled: false,
          to: { name: 'customers' },
        },
        {
          text: this.customerId,
          disabled: true,
          to: { name: 'customer-details' },
        },
      )
    }
    this.constructExportTableHeaders()
  },
  methods: {
    editUser(user) {
      this.user = user
      this.showAddUserDialog = true
    },
    showDeleteUserDialog(id, name) {
      this.showConfirmDeleteDialog = true
      this.toDelete = id
      this.toDeleteCustomerName = name
    },
    getUsers: _.debounce(function () {
      this.isUsersLoading = true
      axios
        .get(this.constructCompleteUsersUrl())
        .then(response => {
          this.users = response.data.data
          this.pagination.page = response.data.current_page
          this.pagination.pageCount = response.data.last_page
          this.isUsersLoading = false
          this.isUsersRefreshing = false
        })
        .catch(error => {
          this.isUsersLoading = false
          this.isUsersRefreshing = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    refreshUsers() {
      this.isUsersRefreshing = true
      this.getUsers()
    },
    deleteUser() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`users/${this.toDelete}`)
        .then(() => {
          this.$notification.success('User deleted successfully')
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
          this.getUsers()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
    applyFilters(filters) {
      this.filters = filters
      this.getUsers()
    },
    onCustomerClick(customerId) {
      this.$router.push({ name: 'more-customer-details', params: { id: customerId } })
    },
    onPageChange(page) {
      this.pagination.page = page
      this.getUsers()
    },
    onItemsPerPageChange(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.getUsers()
    },
    onSearchFilterChange(filter) {
      this.activeSearchFilter = filter
    },
    downloadCSV() {
      this.isDownloadCsvButtonLoading = true
      axios
        .get(`download-users?station_id=${this.selectedStation}`)
        .then(response => {
          if (response.data.users.length > 0) {
            this.usersToDownloadFileName = `${response.data.filename}.csv`
            this.$notification.success('Your download will start shortly')
            this.isDownloadCsvButtonLoading = false
            this.usersToDownload = response.data.users
            this.$refs.export.data = response.data.users
            this.$refs.export.name = `${response.data.filename}.csv`
            this.$refs.export.generate()
          } else {
            this.$notification.error('No customers to download')
            this.isDownloadCsvButtonLoading = false
          }
        })
        .catch(error => {
          console.error(error)
          this.$notification.error(error.response.data.message)
          this.isDownloadCsvButtonLoading = false
        })
    },
    constructCompleteUsersUrl() {
      const accountStatus = JSON.stringify(this.filters.accountBalanceStatus)

      return `${this.userUrl}?station_id=${this.selectedStation}&sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&search=${this.search}&search_filter=${this.activeSearchFilter}&page=${this.pagination.page}&perPage=${this.pagination.itemsPerPage}&status=${accountStatus}`
    },
    constructExportTableHeaders() {
      const headersToExport = [...this.headers]
      headersToExport.pop()
      headersToExport.forEach(element => {
        this.exportTableHeaders.push({ title: element.text, dataKey: element.value })
      })
    },
  },
}
</script>
